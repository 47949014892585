import React from 'react';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import logo from './img/logo.png';
import './App.css';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    detection: {
      order: ['navigator', 'querystring'],
      lookupQuerystring: 'lng',
    },
    resources: {
      en: {
        translation: {
          organizeNow: "ORGANIZE NOW!"
        }
      },
      es: {
        translation: {
          organizeNow: "ORGANIZAR AHORA!"
        }
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

i18n.changeLanguage()

const GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSejkVJV8DFgwGQTUwqKwcufM5YH6zaSkj9xbapOV1jyAG_A7A/viewform'

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-links">
          <a className="App-link" href={GOOGLE_FORM}>{t('organizeNow')}</a>
        </div>
      </header>
    </div>
  );
}

export default App;
